/*---variables---*/
$font-stack: "Helvetica Neue", Helvetica, Arial, sans-serif;

/*---colors---*/
$blue: rgb(0,122,158);
$lightblue: rgb(215,231,237);

$primary-color: $blue;
$secondary-color: $lightblue;

$text-light: #586368;
$text-dark: #34434B;

/*---mobile-first layout---*/
* { box-sizing: border-box; }

html { 
  scroll-behavior: smooth; 
  font: normal 400 1em/1.6 'Work Sans', sans-serif;
}

body { 
  font-size: 1.25rem;
  font-family: $font-stack;
  color: $text-light;
  margin: 0px;
  -webkit-text-size-adjust:100%;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-rendering:optimizeLegibility;
  -webkit-font-feature-settings:"pnum";
  font-feature-settings:"pnum";
  font-variant-numeric:proportional-nums
} 

.container {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
}

h1, h2, h3, h4, h5, h6 { 
  color: $text-dark;
  font-weight: 700; 
}
h1 { 
  font-size: 3rem;
  border-bottom: 3px solid $blue;
  margin-top: 0;
}
h2 { 
  font-size: 2rem;
  color: $blue; 
}

header {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  background: transparent;
  width: 100vw;
  height: 6em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
header.scrolled {
  background: white;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.18);
  height: 5em;

  nav {
    margin-bottom: 0;
    a {
      color: $blue;
    }
    a:hover {
      color: darken($blue, 20);
      border-bottom: 2px solid darken($blue,20);
    }
  }
}

.logo-light {
  display: block;
  width: auto;
  height: 6em;
  margin: 2em 1em 0.5em 5%;
}

.logo-dark {
  display: none;
  width: auto;
  height: 4em;
  margin: 0.5em 1em 0.5em 5%;
  align-self: flex-start;
}

nav {
  align-self: center;
  margin-bottom: 1em;

  ul {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 1em 0 0;
    li {
      display: inline-block;
      margin-right: 1.5em;
    }
  }
  a {
    color: white;
  }
  a:hover {
    color: white;
    border-bottom: 2px solid white;
  }
}

nav label, #hamburger {
  display: none;
}

#header-background {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
 
  background: url(../images/fabian-frey-EPZt4RVTgkQ-unsplash.jpg);
  background-size: cover;
}

.notice {
  display: none;
  text-align: left;
  padding: 2em;
  position: relative;
  max-width: 600px;
  top: 210px;
  color: $text-dark;
  font-size: 1rem;
  line-height: 1.75;
  margin: 0 auto;
  background-color: $lightblue;
  box-shadow: 0px 6px 6px rgba(0,0,0,0.18);
  border: 6px solid $blue;
}

.notice:empty {
  display: none;
}

main {
  top: 18vh;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
}
main.landing {
  top: 90vh;
}

.section-wrapper {
  width: 100vw;
}

section {
  position: relative;
  margin: 0 auto;
  text-align: left;
  padding: 1.2em 2.2em 2em;
  max-width: 900px;
  margin-bottom: 2em;
}
section.landing {
  top: -150px; // offset to bring top section over the header background
}
.section-wrapper:nth-child(odd) {
  background: #fff;
  section {
    background: #fff;
  }
}

.section-wrapper:nth-child(even) {
  background: #fff;
  section {
    background: lighten($lightblue,5);
    border: 6px solid $blue;
  }
}

.team-container {
  display: flex;
  flex-flow: row wrap;
}

.member {
  margin: 0 1em;
  line-height: 1.2;
  p {
    margin-top: 0.3em;
  }

  img {
    height: 300px;
    width: auto;
  }
  .member-name {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .member-position {
    font-size: 1rem;
  }
}

.date {
  font-weight: 700;
  margin-right: 1em;
}
.vita-entry {
  display: flex;
  margin: 2em 0;
  border-bottom: 1px solid $text-light;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-bottom: 0.5em;
}

.opening-entry {
  display: flex;
  margin: 2em 0;
  border-bottom: 1px solid $text-light;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-bottom: 0.5em;
}

.opening-day {
  font-weight: 700;
}

.opening-time {
}

#virtual-tour {
  width: 100%;
  height: 600px;
}

a {
  text-decoration: none;
  color: $blue;
}
a:hover {
  color: darken($blue, 20);
}

footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 24px;
  position: relative;
  top: 700px;
  background: $blue;
  color: white;
  a {
    color: white;
  }
}

@media screen and (max-width: 800px) {

  .section-wrapper:nth-child(even) {
    section {
      background: lighten($lightblue,5);
      border-left: none;
      border-right: none;
    }
  }
}

@media screen and (max-width: 800px) {

  header {
    background: white;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.18);
    height: 5em;
  }

  .logo-light {
    display: none;
  }

  .logo-dark {
    display: block;
  }

  /* Show Hamburger */
  nav label {
    display: block;
    color: $text-dark;
    font-size: 2.5em;
    padding: 0 1em;
  }
  nav label:hover {
    cursor: pointer;
    color: darken($blue, 20);
  }

  nav ul li {
    display: block;
    font-size: 2rem;
  }

  nav ul {
    display: none;
  }
  nav input:checked ~ ul {
    display: block;
    position: fixed;
    top: 100px;
    right: 20px;
    text-align: left;
    background: lighten($lightblue,5);
    border: 6px solid $blue;
    user-select: none;
    padding-left: 1em;
  }
  nav input:checked ~ label {
    color: $blue !important;
  }

  header a {
    color: $blue;
  }

  .date {
    width: 20em;
  }

  .member {
    img {
      height: 250px;
    }
    .member-name {
      font-size: 1.2rem;
    }
    .member-position {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 550px) {
  .opening-day {
    width: 30em;
  }

  .notice {
    font-size: 0.95rem;
    line-height: 1.5;
    padding: 2em 1em;
  }
}

@media screen and (max-width: 500px) {
  // Adjust image size so all fit together (not in one line)
  .member {
    line-height: 1;

    img {
      height: 200px;
    }
    .member-name {
      font-size: 1rem;
    }
    .member-position {
      font-size: 0.9rem;
    }
  }

  nav li a {
    font-size: 1.5rem;
  }

  section {
    padding: 1.2em 1em 2em 0.5em; // wider content section on mobile
  }
}

@media screen and (max-width: 380px) {
  #unsere-praxis {
    font-size: 2.5rem;
  }

  .member {
    line-height: 0.8;

    img {
      height: 140px;
    }
    .member-name {
      font-size: 0.8rem;
    }
    .member-position {
      font-size: 0.7rem;
    }
  }
}
